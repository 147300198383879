import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../../templates/blog-post';
import { graphql } from 'gatsby';
import Challenge from './1';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        promo
        noPic
      }
      fields {
        name
      }
    }
  }
`;
export const _frontmatter = {
  "title": "Flexbox Devtools Challenge 1",
  "date": "2019-05-06",
  "description": "Use the Flex Inspector to fix the Flexbox layout.",
  "color": "#a0da00",
  "noPic": true,
  "draft": false
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Challenge mdxType="Challenge" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      